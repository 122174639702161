import colors from "./jewlr/colors"
/* !!!
 * `core` and `form` reserved for storybook components
 * !!! */
const baseComponents = {
  cart: {
    cartIcon: {
      color: "primary",
    },
    checkoutBtn: {
      borderRadius: { _: 0, tablet: "10px" },
      fontSize: "18px",
      fontWeight: 300,
      letterSpacing: { tablet: "1.5px" },
      marginBottom: { _: 2, tablet: 3 },
      padding: { _: "12px 24px", tablet: "12px 16px" },
    },
    continueShopping: {
      bg: colors.primary,
      borderRadius: "6px",
      color: colors.white,
      display: "inline-block",
      fontSize: "13px",
      letterSpacing: "1px",
      padding: "12px 8px",
    },
    emptyCartBtn: {},
    extraSavingContainer: {
      borderRadius: "10px",
      textAlign: { _: "center", tablet: "left" },
    },
    giftOptions: {
      addonToggle: {
        fontSize: "16px",
        fontWeight: "500",
        letterSpacing: "-0.03em",
        lineHeight: "20px",
      },
      container: {
        borderTop: `1px solid ${colors.grey[10]}`,
        pl: { tablet: 2 },
        py: "14px",
      },
    },
    paymentIcon: {
      color: colors.grey[65],
      fontSize: "19px",
      lineHeight: 1,
    },
    paymentModal: {
      method: {
        borderBottom: `1px solid ${colors.grey[10]}`,
        padding: "10px 20px",
      },
      methodDetails: {
        color: colors.grey[65],
        fontSize: "12px",
        letterSpacing: "0.5px",
      },
      methodIcon: `
        margin-right: 10px;
        width: 35px;
      `,
      methodName: {
        fontSize: "16px",
        fontWeight: 300,
        letterSpacing: "0.5px",
      },
      wrapper: {
        bg: colors.grey[4],
        borderTop: `1px solid ${colors.grey[10]}`,
      },
    },
    popup: {
      addonContainer: {
        mt: "20px",
      },
      addonImageContainer: {
        height: "60px",
        width: "100px",
      },
      addons: {
        addToCart: {
          borderRadius: { _: "4px", tablet: 0 },
          height: { _: "26px", tablet: "32px" },
          minWidth: { _: "88px", tablet: "92px" },
        },
        addonItem: {
          border: { _: `1px solid ${colors.grey[10]}`, tablet: "none" },
        },
        addonName: {},
        container: {
          bg: { _: colors.grey[4], tablet: colors.white },
        },
        descriptionContainer: {
          bg: colors.grey4,
          pb: 4,
          pt: 2,
          px: 3,
        },
        detailWrapper: {
          pt: { _: "10px", tablet: 3 },
        },
        listPriceCurrency: {
          lineHeight: "30px",
        },
        listPriceValue: {
          lineHeight: "30px",
        },
        priceTag: {
          pb: { _: 2, tablet: "10px" },
        },
        qtyBox: {
          height: { _: "26px", tablet: "32px" },
          minWidth: { _: "88px", tablet: "92px" },
        },
        qtyValue: {},
        retailPrice: {
          color: colors.grey[62],
        },
        subtitle: {
          fontSize: "14px",
          lineHeight: "16px",
        },
      },
      bmsm: {
        icon: {
          color: "#088C04",
        },
        msgTitle: {
          color: "#088C04",
        },
        promoMsg: {
          bg: "#dbf0cf",
        },
        tagLine: {},
      },
      checkoutButton: {
        fontSize: "18px",
        lineHeight: "21px",
      },
      continueButton: {
        border: "1px solid #d9d9d9",
        color: colors.black,
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "19px",
      },
      ctaButton: {
        height: "44px",
      },
      ctaContainer: {
        gap: "12px",
        mt: "12px",
      },
      giftAccordionTitle: {
        color: colors.primary,
      },
      giftOptionContainer: {
        borderBottom: "1px solid #d9d9d9",
        borderTop: { desktop: "1px solid #D9D9D9" },
      },
      giftOptionContainerShowGift: `
       background-color: ${colors.grey[4]};
       padding: 16px 24px 8px;
      `,
      giftPackageWrapper: {
        mt: "12px",
      },
      itemImage: {
        maxWidth: { _: "185px", desktop: "200px" },
      },
      itemTag: {
        mb: 2,
      },
      itemText: {},
      packageItem: `
        border-color: #D9D9D9;
        flex: 0 0 110px;
        padding: 4px;
      `,
      packageItemName: {
        color: colors.grey[55],
      },
      packageItemSelected: "",
      perksIconImage: {
        height: "30px",
      },
      perksIconText: {
        color: colors.grey[55],
      },
      perksWrapper: {
        pt: { _: 2, desktop: 0 },
      },
      shippingText: {},
      summary: {
        bmsmText: {},
        promoMsg: {
          bg: "#dbf0cf",
        },
        totals: {
          mb: "4px",
        },
        totalsPrice: {},
      },
      title: {},
    },
    promoToggle: {
      py: 2,
    },
  },
  checkout: {
    components: {
      tooltip: `
        background-color: #F6FAFF !important;
      `,
    },
    contactInfo: {
      checkboxText: {
        color: { _: colors.grey[65], desktop: colors.black },
      },
      continueBtnWrapper: {
        mt: { _: 2, desktop: 3 },
      },
      emailInputWrapper: {},
      forgotPassword: {
        cancelBtn: {},
        closeBtn: {
          base: {},
          hover: "",
        },
        instruction: {},
        submitBtn: {
          width: "100%",
        },
        successMsg: {
          bg: "#0088000d",
          px: "30px",
        },
        title: { mb: 3 },
      },
      login: {
        cancelBtn: {
          color: colors.primary,
        },
        resetPasswordBtn: {
          color: colors.primary,
        },
        title: {
          mb: 3,
        },
      },

      signIn: {
        color: colors.primary,
      },
      signInMobile: {
        color: "primary",
      },
      signInMsg: {},
      signInWrapper: {
        bg: "#2568b20f",
      },
    },
    continueBtn: {
      borderRadius: "5px",
      width: { _: "100%", desktop: "260px" },
    },
    footer: {
      container: {
        mt: { _: 5, desktop: "100px" },
      },
    },
    helpFooter: {
      accordionIcon: {
        color: { _: "neutral", desktop: "primary" },
      },
      accordionTitle: {
        main: {
          desktop: `
            color: ${colors.primary};
          `,
        },
      },
      helpLink: {
        desktop: `
          text-decoration: none;
          color: ${colors.primary};
          &:hover {
            color: ${colors.primary};
          }
          &:focus {
            color: ${colors.primary};
          }
        `,
      },
      helpText: {
        fontSize: { _: "14px", desktop: "12px" },
        lineHeight: { _: "16px", desktop: "14px" },
      },
    },
    orderSummary: {
      itemRow: {
        py: "12px",
      },
      refNum: {
        mb: "42px",
      },
      summaryOrderTotal: {
        mt: "20px",
      },
      summaryShipping: {
        mt: "20px",
      },
    },
    orderSummaryModal: {
      editCart: {
        color: "primary",
      },
    },
    overviewContent: {
      color: colors.grey[65],
      shipAddr: {},
      shipMethod: {},
      shipMethodTime: {},
    },
    panelName: {},
    panelToggle: {
      color: colors.primary,
    },
    paymentButton: {
      base: "",
      selected: `
      border: 1px solid ${colors.primary};
      border-radius: 5px;
    `,
    },
    paymentMethod: {
      applyGiftBtn: {},
      creditCardInput: `
        border: 1px solid ${colors.grey[30]};
      `,
      giftCardHead: {
        color: "primary",
      },
      icon: {
        color: "primary",
        width: "16px",
      },
      redeemedCreditBox: {
        bg: "#0088000d",
        lineHeight: "17px",
      },
      toggleIcon: {
        color: "primary",
      },
    },
    placeOrderBtn: {
      borderRadius: "5px",
      fontSize: "18px",
      fontWeight: 700,
      width: { _: "100%", desktop: "280px" },
    },
    popupModalCancelBtn: {
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "16px",
    },
    shipAddress: {
      switchCountryBtn: {
        color: "primary",
      },
      toggleIcon: {
        color: "primary",
      },
    },
    shipMethod: {
      checkboxWrapper: {},
      continueBtnWrapper: {},
      label: {
        selected: `
          background-color: #8aa16a1a;
          border: 1px solid ${colors.greenDarker};
        `,
      },
      methodDate: {
        color: colors.black,
      },
      methodInfo: {},
      methodPrice: {
        fontWeight: "normal",
      },
      shipPrice: {
        lineHeight: "24px",
      },
    },
    sidePanelEditCart: `
      color: ${colors.primary};
      font-size: 14px;
      font-weight: 300;
      &:hover {
        color: ${colors.primary};
      }
    `,
    sidePanelSummaryTitleContainer: {
      mb: "10px",
    },
    sidePanelTitle: {},
    sidePanelTitleContainer: {
      mb: "10px",
    },
    sideReward: {
      applyBtn: {},
      redeemIcon: {
        color: colors.primary,
      },
      redeemPtsToggle: {
        color: colors.primary,
      },
      redeemedCreditBox: {
        bg: "#0088000d",
      },
    },
    slideModalCloseBtn: {
      borderRadius: "5px",
      fontSize: "16px",
      fontWeight: "500",
    },
    stepIcon: {
      bg: colors.black,
    },
    vipRewards: {
      applyButton: {},
      continueBtn: {
        borderRadius: "5px",
        mb: 7,
        width: "148px",
      },
      icon: {
        color: "primary",
        width: "16px",
      },
      levelText: {},
      redeemAmount: {
        color: colors.grey[65],
      },
      redeemedCreditBox: {
        bg: "#0088000d",
      },
      subtitle: {},
      tierWrapper: {},
      title: {
        fontSize: "20px",
        lineHeight: "24px",
      },
    },
  },
  checkoutComplete: {
    confirmButton: `
      background-color: ${colors.primary};
      color: white;
      font-size: 16px;
      padding: 16px 32px;
      :hover {
        color: white;
      }
    `,
    extend: {
      message: {
        lineHeight: { _: "2em", tablet: "1.5em" },
        mt: 2,
      },
      planContainer: {
        mx: "auto",
        py: { _: 2, tablet: 3 },
        width: { desktop: "70%", tablet: "90%" },
      },
      planImage: {
        height: "30px",
        mb: { _: 2, tablet: 0 },
        mr: { tablet: 2 },
      },
      planWrapper: {
        py: { _: 1, tablet: 0 },
      },
      wrapper: {
        bg: colors.grey5,
        pb: 5,
        pt: 3,
        px: 2,
      },
    },
  },
  diamondSelector: {
    customizeDescription: { mb: 3 },
    navigateNext: {
      bg: "black",
      color: "white",
      fontSize: "13px",
      padding: "8px 24px",
    },
  },
  extendWarranty: {
    cartAddon: { color: "#BDE2F4", selected: colors.selected },
    faqLink: {
      color: colors.black,
    },
    protectionPlanBanner: {
      bg: "#EFF1F0",
    },
    startShopping: {
      bg: colors.polar,
      mb: { tablet: "-32px" },
    },
    textColor: {
      color: colors.primary,
    },
    warrantyTitle: {
      bg: "#ababab",
      pb: 2,
    },
  },
  header: {
    navBar: {
      maxWidth: "1080px",
      px: 6,
    },
    navIcon: {
      color: "blackPrint",
      fontSize: { _: "18px", desktop: "20px", widescreen: "24px" },
    },
  },
  helpdesk: {
    box: { background: colors.grey[10], text: colors.black },
    contact: {
      py: { _: 5, tablet: 3 },
      textDecoration: "none",
    },
    contactButton: {
      attrs: {},
      style: `
        background-color: black;
        color: white;
        padding-bottom: 8px;
        padding-top: 8px;
        width: 90%;
        &:hover {
          color: white;
        }
      `,
    },
    inlineLink: {
      color: colors.primary,
    },
    input: {
      background: colors.grey[10],
      border: "none",
    },
    link: {
      arrow: "none",
      outline: "none",
      underline: "underline",
    },
    searchBoxlink: {
      color: colors.primary,
      underline: "none",
    },
  },
  info: {
    accessibilityInfo: {
      titleBox: {},
    },
    titleBlock: {
      pb: { _: 4, desktop: "36px" },
      pt: { _: "41px", desktop: "60px" },
    },
  },
  infoGiftCards: {
    checkoutButton: { fontSize: "16px", fontWeight: "normal" },
    continueButton: { fontSize: "18px", fontWeight: "300" },
    flexBlock: { justifyContent: "space-between" },
    flexImageBox: { flexBasis: "50%" },
    flexTextBox: { flexBasis: "45%" },
    giftCardLink: { fontWeight: "normal" },
    mainContainer: { bg: "white" },
    mobileTitleDescription: { mt: 0 },
    textTransform: "capitalize",
  },
  landing: {
    text: {
      description: {},
      title: {},
    },
  },
  loginForm: {
    cta: {
      height: "44px",
    },
    fieldContainer: {
      mb: 2,
      textAlign: "left",
    },
    footnote: {
      color: "neutral",
      fontSize: "12px",
      fontWeight: "500",
      letterSpacing: "-0.03em",
      lineHeight: { _: "16px", tablet: "14px" },
      textAlign: "center",
    },
    forgotPassword: {
      color: "primary",
      fontSize: "12px",
      fontWeight: "700",
      letterSpacing: "-0.03em",
      lineHeight: "18px",
    },
    haveAccount: {
      fontSize: "14px",
      fontWeight: "500",
      letterSpacing: "-0.03em",
      lineHeight: "18px",
      textAlign: "center",
    },
    loginButton: {
      color: colors.primary,
      fontSize: "14px",
      fontWeight: "500",
      letterSpacing: "-0.03em",
      lineHeight: "18px",
      underline: true,
    },
    rememberMeContainer: {
      mb: 3,
    },
    rememberMeLabel: {
      fontSize: "12px",
      fontWeight: "500",
      letterSpacing: "-0.03em",
      lineHeight: "20px",
      ml: "8px",
    },
    subscribeBox: {
      mb: 2,
    },
    subscribeText: {
      color: colors.grey[65],
      fontSize: "12px",
      fontWeight: "500",
      letterSpacing: "-0.03em",
      lineHeight: "15px",
      ml: "12px",
    },
  },
  miniview: {
    wrapper: {
      top: "106px",
    },
  },
  modal: {
    cta: {
      bg: colors.primary,
    },
  },
  order: {
    link: {
      color: colors.primary,
      textDecoration: "none",
    },
  },
  payment: {
    btn: {
      bg: colors.primary,
      borderRadius: "4px",
    },
    navTab: {
      color: colors.primary,
    },
  },
  paymentPlans: {
    button: { bg: "", color: "", outline: "black" },
    iconDescription: { px: 2 },
    iconImage: {
      knowWhat: { width: "85px" },
      prequalify: { width: "70px" },
      worryFree: { width: "70px" },
    },
    imageBanner: {
      bannerText: { fontSize: "" },
      contain: false,
    },
    klarna: {
      bannerBlock: {
        bg: "#E1E8F2",
        mb: 5,
      },
      contentBox: { px: { _: 2, tablet: 0 } },
      klarnaBox: { contain: false },
      klarnaTitle: { bg: "#979797", mb: 3 },
    },
    terms: { fontWeight: "normal", textDecoration: "" },
  },
  radioButton: {
    after: {
      base: `
        border: 1px solid ${colors.primary};
      `,
      checked: `
        background-color: ${colors.greenDarker};
        border-color: ${colors.greenDarker};
      `,
    },
    before: `
      border-color: ${colors.white};
      border-style: solid;
      border-width: 0 2px 2px 0;
      content: "";
      height: 10px;
      left: 5px;
      position: absolute;
      top: 50%;
      transform: translateY(-60%) rotate(45deg);
      width: 5px;
      z-index: 2;
    `,
    label: `
      height: 22px;
      width: 22px;
    `,
  },
  search: {
    subtitle: {
      fontSize: "12px",
      italic: true,
    },
    suggestTitle: `
      font-size: 14px;
      letter-spacing: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `,
    suggestionItem: "padding: 8px 0;",
  },
  shipModal: {
    button: { background: colors.primary },
    heading: { background: colors.polar },
  },
  subscriptionSignup: {},
  titleWithLine: {
    bg: "#ababab",
    pb: 2,
  },
  wizardChoice: {
    borderRadius: "5px",
    boxShadow: "0 0px 0px rgba(0, 0, 0, 0.15)",
    flexBasis: { _: "21%", tablet: "73px" },
    mb: 3,
    padding: "4px 2px 8px",
    showCheckmark: true,
  },
  wizardChoicePrice: {},
  wizardChoiceTitle: {
    mb: 1,
    mt: 1,
  },
  wizardErrorBox: {
    bg: colors.warning,
    borderRadius: "5px",
    color: colors.black,
  },
}

export default baseComponents
