import baseComponents from "../base-components"

import colors from "./colors"

const theme = {
  ...baseComponents,
  colors: {
    ...colors,
  },
  organization: "jewlr4",
}

export default theme
