import { jewlrTheme } from "@jewlr/storybook/themes"

import baseColors from "../base-colors"

const colors = {
  ...jewlrTheme.colors,
  ...baseColors,
}

export default colors
