// Base colors needed for all organizations
const baseColors = {
  /* eslint sort-keys: 0 */
  grey4: "#F4F4F4",
  grey5: "#f1f1f1",
  grey10: "#ebebeb",
  grey20: "#ccc",
  grey30: "#a6a6a6",
  grey35: "#a0a0a0",
  grey45: "#9B9B9B",
  grey65: "#646464",

  guaranteed: "#20A41C",
  guaranteedBackground: "#FADBDD",
  guaranteedCaret: "#BC3636",

  skus: {
    cer: "#646464",
    cob: "#CCCCCC",
    kr: "#F4CEB2",
    tkr: "#F4CEB2",
    kw: "#EFEFEF",
    tkw: "#EFEFEF",
    ky: "#F8E7AD",
    tky: "#F8E7AD",
    ss: "#CCCCCC",
    tss: "#CCCCCC",
    sst: "#CCCCCC",
    tung: "#CCCCCC",
    vermy: "#CCCCCC",
    sskyp: "#CCCCCC",
  },
}

export default baseColors
