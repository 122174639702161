import baseComponents from "../base-components"

import colors from "./colors"
import components from "./components"

const theme = {
  ...baseComponents,
  ...components,
  colors: {
    ...colors,
  },
  organization: "portal",
}

export default theme
